<template>
  <div class="coach">
    <img
      src="../../assets/image/fudao/bg.png"
      style="height: 440px; width: 100vw"
      alt=""
    />
    <div class="content2">
      <div class="f1">
        <img src="../../assets/image/fudao/guihua.png" />
      </div>
    </div>
    <div class="bgc f7">
      <div class="content3">
        <div class="youhua">
          <img src="../../assets/image/fudao/youhua.png" />
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="f1">
        <img src="../../assets/image/fudao/mianshi.png" />
      </div>
      <div class="title">
        <div class="english-yellow">{{$t('coach.Entered')}}</div>
        <div class="service-con">
          <div class="servicetop">{{$t('coach.已经进入的名企列表')}}</div>
          <div class="line2"></div>
        </div>
      </div>
      <div class="mingqi">
        <img src="../../assets/image/company.png" />
      </div>
      <div class="title">
        <div class="english-yellow">{{$t('coach.Serviceprocess')}}</div>
        <div class="service-con">
          <div class="servicetop">{{$t('coach.服务流程')}}</div>
          <div class="line2"></div>
        </div>
      </div>
      <div class="liucheng">
        <img src="../../assets/image/fudao/liucheng.png" />
      </div>
    </div>
    <div class="bgc af6">
      <div class="content3">
        <div class="title">
          <div class="english-yellow">{{$t('coach.Successstories')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('coach.成功案例')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="successList">
          <!-- <div v-for="(item, index) in 5" :key="index">
            <div class="img"><img src="../../assets/image/keyan/bg.png" /></div>
            <div class="text">刘老师</div>
            <div class="text2">
              刚刚从海外硕士留学还没有毕业，对国内的求职完全迷茫。经过求职辅导以后，在辅导周期三个月的时间内，成功拿到华为产品设计岗offer，年薪36W，成功和同龄小伙伴拉开差距。
            </div>
          </div> -->
          <img src="../../assets/image/cgal.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {};
    },
  };
</script>
<style lang="scss">
  .coach {
    .f7 {
      background-color: #f7f7f7;
    }
    .af6 {
      background-color: #fefaf6;
      padding-bottom: 50px;
    }
    .bgc {
      width: 100%;
      padding-top: 1px;

      .content3 {
        width: 1176px;
        margin: 0 auto;
        .successList {
          display: flex;
          justify-content: space-around;
          img{
            width: 1176px;
            height: 470px;
          }
          > div {
            width: 220px;
            height: 470px;
            background: #ffffff;
            border-radius: 12px 12px 12px 12px;
            padding: 30px 21px 30px 21px;
            transition: all 0.5s;
            color: #333333;
            margin-bottom: 70px;
            .text2 {
              font-size: 16px;
              font-weight: 400;

              line-height: 28px;
            }
            .text {
              margin: 20px 0 17px 0;
              font-size: 26px;
              font-weight: bold;
            }
            .img {
              > img {
                width: 120px;
                height: 120px;
                border-radius: 50%;
              }
            }
          }
          > div:hover {
            background-color: #fe992b;
            // color: rgb(240, 240, 240);
          }
        }
        .youhua {
          padding: 70px 0 70px 0;
          > img {
            width: 1176px;
            height: 320px;
          }
        }
      }
    }
    .content2 {
      width: 1176px;
      margin: 0 auto;
      .liucheng {
        padding-bottom: 70px;
        > img {
          width: 1176px;
          height: 352px;
        }
      }
      .mingqi {
        padding-bottom: 20px;
        > img {
          width: 1176px;
          height: 400px;
        }
      }
      .f1 {
        padding: 70px 0 70px 0;
        > img {
          width: 1176px;
          height: 320px;
        }
      }
    }
    .title {
      position: relative;
      height: 150px;
      margin-top: 50px;

      .english-yellow {
        color: #fe992b;
        font-size: 44px;
        opacity: 0.3;
      }

      .service-con {
        width: 100%;
        position: absolute;
        top: 17px;
      }

      .line2 {
        width: 124px;
        height: 6px;
        border-radius: 3px;
        background-color: #fe992b;
        margin: 0 auto;
      }

      .servicetop {
        // width: 160px;
        font-size: 32px;
        color: #333;
        font-weight: bold;
        padding: 10px 0;
        // border-bottom: 6px solid #fe992b;
        border-radius: 3px;
        margin: 0 auto;
      }
    }
  }
</style>
